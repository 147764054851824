import { subHours } from 'date-fns';
import useSWR from 'swr';

import {
  HomeShowsDocument,
  HomeShowsQuery,
  HomeShowsQueryVariables,
  Ordering,
  ShowState,
} from '@/generated/graphql';
import client from '@/graphql/apollo';

function useHomeShowsQuery(limit: number) {
  return useSWR([`/home`], async () => {
    const response = await client.query<
      HomeShowsQuery,
      HomeShowsQueryVariables
    >({
      query: HomeShowsDocument,
      variables: {
        first: limit,
        upcomingSlotsFilters: {
          NOT: {
            show: {
              state: {
                exact: ShowState.Cancelled,
              },
            },
          },
          confirmed: {
            exact: true,
          },
          startDatetime: {
            gte: subHours(new Date(), 6).toISOString(),
          },
        },
        pastSlotsFilters: {
          NOT: {
            show: {
              state: {
                exact: ShowState.Cancelled,
              },
            },
          },
          confirmed: {
            exact: true,
          },
          startDatetime: {
            lt: subHours(new Date(), 6).toISOString(),
          },
        },
        upcomingSlotsOrder: {
          startDatetime: Ordering.Asc,
        },
        pastSlotsOrder: {
          startDatetime: Ordering.Desc,
        },
      },
    });

    if (!response.data.me) {
      throw new Error(`User is not logged in`);
    }

    const { recommendedSlots } = response.data.me;
    const { upcomingSlots } = response.data.me;
    const { pastSlots } = response.data.me;

    return {
      recommended: {
        hasNext: recommendedSlots?.pageInfo.hasNextPage ?? false,
        list: recommendedSlots?.edges.map((it) => it.node) ?? [],
      },
      upcoming: {
        hasNext: upcomingSlots?.pageInfo.hasNextPage ?? false,
        list: upcomingSlots?.edges.map((it) => it.node) ?? [],
      },
      past: {
        hasNext: pastSlots?.pageInfo.hasNextPage ?? false,
        list: pastSlots?.edges.map((it) => it.node) ?? [],
      },
    };
  });
}

export default useHomeShowsQuery;
