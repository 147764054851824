import { UilEllipsisH } from '@iconscout/react-unicons';
import { ReactNode } from 'react';

function EmptyShowList(props: { children: ReactNode }) {
  const { children } = props;

  return (
    <div className="flex-center flex-col gap-12">
      <div className="flex-center size-64 rounded-100 bg-gray-500">
        <UilEllipsisH className="size-32 fill-gray-50" />
      </div>

      <span className="body-2-r whitespace-pre-line text-center text-gray-100">
        {children}
      </span>
    </div>
  );
}

export default EmptyShowList;
