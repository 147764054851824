'use client';

import { ReactNode, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import AppBar from '@/components/Layout/AppBar';
import Background from '@/components/Layout/Background';
import Container from '@/components/Layout/Container';
import MobilePx8 from '@/components/Layout/MobilePx8';
import HomeShowsSection from '@/components/Molecule/Home/HomeFutureShowsSection';
import HomePastShowSection from '@/components/Molecule/Home/HomePastShowSection';
import useFetching from '@/hooks/useFetching';
import useHomeShowsQuery from '@/hooks/useHomeShowsQuery';

function Wrapper(props: { children?: ReactNode }) {
  const { children } = props;

  return (
    <Background backgroundColor="#F8F8F8">
      <AppBar
        start={<AppBar.MenuButton />}
        end={<AppBar.ProfileButton />}
        backgroundColor="#F8F8F8"
      />
      <div className="h-10 md:h-64" />

      <Container className="md:max-w-632 lg:max-w-960 xl:max-w-976 2xl:max-w-1312">
        <MobilePx8>{children}</MobilePx8>
      </Container>
    </Background>
  );
}

function Home() {
  const isLg = useMediaQuery({
    query: `(min-width: 1024px)`,
  });
  const isMd = useMediaQuery({
    query: `(min-width: 768px)`,
  });
  const is2xl = useMediaQuery({
    query: `(min-width: 1536px)`,
  });
  const visibleCount = useMemo(() => {
    if (is2xl) return 4;
    if (isLg) return 3;
    if (isMd) return 2;

    return 3;
  }, [is2xl, isLg, isMd]);

  const { data, isLoading, error } = useHomeShowsQuery(5);

  useFetching({ data, isLoading, error });

  if (!data) {
    return null;
  }
  const { recommended, past, upcoming } = data;

  return (
    <Wrapper>
      <div className="flex w-full flex-col gap-16 md:gap-160">
        <HomeShowsSection
          recommendedShows={recommended}
          upcomingShows={upcoming}
          visibleCount={visibleCount}
        />
        <HomePastShowSection shows={past} visibleCount={visibleCount} />
      </div>
    </Wrapper>
  );
}

export default Home;
