import { Dispatch, ReactNode, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { SlotType } from '@/models/Slot';

function Tab(props: {
  focused: boolean;
  children?: ReactNode;
  onClick: {
    (_: unknown): unknown;
  };
}) {
  const { focused, children, onClick } = props;

  return (
    <button
      className={twMerge(
        `flex flex-col gap-10 text-left`,
        focused ? `text-gray-25` : `text-[#C8C4CF]`,
      )}
      onClick={onClick}
    >
      <div
        className={twMerge(
          `h-4 w-16 rounded-30 bg-gray-25`,
          focused ? `visible` : `invisible`,
        )}
      />
      {children}
    </button>
  );
}

function ShowsTabs(props: {
  tab: SlotType.Recommended | SlotType.Upcoming;
  onTabChange: Dispatch<SlotType.Recommended | SlotType.Upcoming>;
  className?: string;
}) {
  const { tab, onTabChange, className } = props;

  const handleRecommendedTabClick = useCallback(() => {
    onTabChange(SlotType.Recommended);
  }, [onTabChange]);

  const handleUpcomingTabClick = useCallback(() => {
    onTabChange(SlotType.Upcoming);
  }, [onTabChange]);

  return (
    <div className="flex justify-between">
      <div className="flex gap-32">
        <Tab
          focused={tab === SlotType.Recommended}
          onClick={handleRecommendedTabClick}
        >
          <span className={twMerge(``, className)}>Recommended Shows</span>
        </Tab>
        <Tab
          focused={tab === SlotType.Upcoming}
          onClick={handleUpcomingTabClick}
        >
          <span className={twMerge(``, className)}>Upcoming Shows</span>
        </Tab>
      </div>
    </div>
  );
}

export default ShowsTabs;
