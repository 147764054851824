import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import EmptyShowList from '@/components/Molecule/Home/EmptyShowList';

function SlotListWrapper(props: {
  children?: ReactNode;
  className?: string;
  messageIfEmpty: string;
  empty: boolean;
}) {
  const { children, className, messageIfEmpty, empty } = props;

  if (empty) {
    return (
      <div className="md:flex-center md:h-239 md:w-full md:rounded-20 md:bg-white">
        <EmptyShowList>{messageIfEmpty}</EmptyShowList>
      </div>
    );
  }

  return <div className={twMerge(className)}>{children}</div>;
}

export default SlotListWrapper;
