import { UilArrowRight } from '@iconscout/react-unicons';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import SlotCard from '@/components/Molecule/Show/SlotCard';
import SlotListWrapper from '@/components/Molecule/Show/SlotListWrapper';
import { Card } from '@/components/ui/card';
import { MySlotFragment } from '@/generated/graphql';
import { InternalLink, RouterPathType } from '@/hooks/useInternalRouter';
import { SlotType } from '@/models/Slot';
import Join from '@/utils/Join';

function MySlotListCard(props: {
  slots: MySlotFragment[];
  slotType: SlotType;
  title: string;
  href: RouterPathType;
  messageIfEmpty: string;
}) {
  const { slots, slotType, title, href, messageIfEmpty } = props;

  return (
    <Card>
      <div className="flex w-full flex-col gap-32 p-16">
        <div className="flex items-center justify-between">
          <span className="header-3-b text-gray-10">{title}</span>
          <InternalLink href={href}>
            <div className="flex-center size-36 rounded-full bg-gray-500">
              <UilArrowRight className="size-24 fill-gray-25" />
            </div>
          </InternalLink>
        </div>
        <SlotListWrapper
          className={twMerge(
            slotType === SlotType.Past ? `gap-16` : `gap-24`,
            `flex flex-col`,
          )}
          messageIfEmpty={messageIfEmpty}
          empty={slots.length === 0}
        >
          <Join by={<div className="h-1 bg-gray-500" />}>
            {slots.map((slot) => (
              <SlotCard slot={slot} type={slotType} key={slot.uuid} />
            ))}
          </Join>
        </SlotListWrapper>
      </div>
    </Card>
  );
}

export default MySlotListCard;
