import { UilClock } from '@iconscout/react-unicons';
import { UisCheckCircle } from '@iconscout/react-unicons-solid';
import { twMerge } from 'tailwind-merge';

function PayoutBadge(props: { payoutSent: boolean }) {
  const { payoutSent } = props;

  return (
    <div
      className={twMerge(
        `flex w-full items-center gap-3`,
        payoutSent ? `text-green-200` : `text-gray-200`,
      )}
    >
      {payoutSent ? (
        <UisCheckCircle className="size-16 fill-green-200" />
      ) : (
        <UilClock className="size-16 fill-gray-200" />
      )}

      <span>{payoutSent ? `Payout Sent` : `Payout Pending`}</span>
    </div>
  );
}

export default PayoutBadge;
