import { UilCalendarAlt, UilUsdCircle } from '@iconscout/react-unicons';
import { parseISO } from 'date-fns';
import React from 'react';

import ExpiringInBadge from '@/components/Molecule/ExpiringInBadge';
import PayoutBadge from '@/components/Molecule/Home/PayoutBadge';
import { MySlotFragment, SlotFragment } from '@/generated/graphql';
import {
  InternalLink,
  InternalLinkProps,
  RouterPathBuilderUtil,
} from '@/hooks/useInternalRouter';
import { getOfferText } from '@/models/Offer';
import { formatSlotModel, SlotType } from '@/models/Slot';
import { SlotUtils } from '@/models/SlotDetail';
import ImageWithFallback from '@/utils/ImageWithFallback';

function FutureSlotCard(props: {
  slot: MySlotFragment | SlotFragment;
  artist?: {
    name: string;
    photo: string;
  };
}) {
  const { slot, artist } = props;

  const isMySlot = `offer` in slot;
  const linkProps: InternalLinkProps = isMySlot
    ? {
        builder: RouterPathBuilderUtil.fromSlot(slot),
      }
    : { href: null };

  const offerText = isMySlot
    ? getOfferText(slot.ntRec?.offer ?? slot.offer)
    : null;

  return (
    <InternalLink {...linkProps} className="contents">
      <div className="flex flex-col gap-16">
        <div className="relative aspect-[327/200] w-full shrink-0 md:aspect-[304/240] md:w-304">
          <ImageWithFallback
            key={SlotUtils.getSlotImage(slot)[0]}
            src={SlotUtils.getSlotImage(slot)[0]}
            sizes="100vw"
            layout="fill"
            className="shrink-0 rounded-12 object-cover"
          />
          <div className="absolute flex w-full items-start justify-end p-16">
            {`ntRec` in slot && slot.ntRec && (
              <ExpiringInBadge expiresAt={parseISO(slot.ntRec.expiresAt)} />
            )}
          </div>
        </div>

        <div className="flex flex-col ">
          <span className="label-xxsmall truncate text-gray-100">
            {slot.show.stage.venue.shortNameWithoutRegion}
          </span>
          <div className="h-4" />
          <span className="label-small truncate text-gray-10">
            {slot.show.name}
          </span>
          <div className="h-8" />
          <div className="flex items-center gap-8">
            <UilCalendarAlt className="size-16 shrink-0 fill-gray-25" />
            <div className="label-xsmall text-gray-25">
              {formatSlotModel(slot, {
                type: `start`,
                formatStartDatetime: `MM/dd`,
              })}
            </div>
            <span className="paragraph-small truncate text-gray-25">
              {formatSlotModel(slot, {
                type: `both`,
                formatStartDatetime: `h:mm a`,
                formatEndDatetime: `h:mm a zzz`,
              })}
            </span>
          </div>
          <div className="h-4" />
          <div className="flex items-center gap-8">
            <UilUsdCircle className="size-16 shrink-0 fill-gray-25" />
            <span className="paragraph-small truncate text-gray-25">
              {offerText}
            </span>
          </div>
        </div>

        {artist && (
          <div className="flex items-center gap-8">
            <ImageWithFallback
              key={artist.photo}
              src={artist.photo}
              width={24}
              height={24}
              className="rounded-full"
            />
            <span className="label-xsmall">{artist.name}</span>
          </div>
        )}
      </div>
    </InternalLink>
  );
}

function PastSlotCard(props: { slot: MySlotFragment | SlotFragment }) {
  const { slot } = props;
  const isMySlot = `offer` in slot;
  const linkProps: InternalLinkProps = isMySlot
    ? {
        builder: {
          type: `slot-detail`,
          slotUuid: slot.uuid,
        },
      }
    : { href: null };

  const artist = slot.selectedArtist;

  return (
    <InternalLink {...linkProps}>
      <div className="flex flex-col gap-16">
        <div className="relative aspect-[322/200] w-full shrink-0 md:aspect-[304/240] md:w-304">
          <ImageWithFallback
            key={SlotUtils.getSlotImage(slot)[0]}
            src={SlotUtils.getSlotImage(slot)[0]}
            sizes="100vw"
            layout="fill"
            className="shrink-0 rounded-12 object-cover"
          />
        </div>

        <div className="flex flex-col gap-6 overflow-hidden">
          <div className="flex flex-col gap-3">
            <span className="label-xxsmall truncate text-gray-100">
              {slot.show.stage.venue.shortNameWithoutRegion}
            </span>
            <span className="label-small truncate text-gray-10">
              {slot.show.name}
            </span>
          </div>
          <div className="flex items-center gap-8">
            <span className="paragraph-small truncate text-gray-25">
              {formatSlotModel(slot, {
                type: `start`,
                formatStartDatetime: `MM/dd/yyyy`,
              })}
            </span>
            {isMySlot && (
              <span className="label-xsmall">
                <PayoutBadge payoutSent={slot.payoutSent ?? false} />
              </span>
            )}
          </div>
        </div>
        {artist && (
          <div className="flex items-center gap-8">
            <ImageWithFallback
              key={artist.photo}
              src={artist.photo}
              width={24}
              height={24}
              className="rounded-full"
            />
            <span className="label-xsmall">{artist.name}</span>
          </div>
        )}
      </div>
    </InternalLink>
  );
}

function SlotCard(props: {
  slot: MySlotFragment | SlotFragment;
  type: SlotType;
}) {
  const { slot, type } = props;

  switch (type) {
    case SlotType.Upcoming: {
      return (
        <FutureSlotCard slot={slot} artist={slot.selectedArtist ?? undefined} />
      );
    }
    case SlotType.Recommended: {
      const artist =
        `ntRec` in slot
          ? slot.ntRec?.artist ?? slot.recommendation?.artist
          : undefined;

      return <FutureSlotCard slot={slot} artist={artist} />;
    }
    case SlotType.Past: {
      return <PastSlotCard slot={slot} />;
    }
    default: {
      throw new Error(`Invalid slot type: ${type}`);
    }
  }
}

export default SlotCard;
