import * as React from 'react';

import { cn } from '@/lib/utils';

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      `rounded-16 bg-white text-card-foreground shadow-1 md:shadow-2`,

      className,
    )}
    {...props}
  />
));

Card.displayName = `Card`;

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(`flex flex-col p-16 md:p-24`, className)}
    {...props}
  />
));

CardHeader.displayName = `CardHeader`;

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3
    ref={ref}
    className={cn(`heading-medium md:heading-large`, className)}
    {...props}
  />
));

CardTitle.displayName = `CardTitle`;

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      `paragraph-medium break-words px-16 pb-20 pt-16 text-gray-50 md:p-24`,
      className,
    )}
    {...props}
  />
));

CardContent.displayName = `CardContent`;

const CardDivider = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(`border-t border-gray-500`, className)}
    {...props}
  />
));

CardDivider.displayName = `CardDivider`;

export { Card, CardHeader, CardTitle, CardContent, CardDivider };
